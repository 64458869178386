import React from "react";
import "../../Styles";
import ProtectedRoutes from "../../Routes/ProtectedRoutes";
import { Footer, Header, SideBar } from "../../Components";
import { useState, useEffect } from "../../Hooks";

const MainLayout = () => {
	// States...
	const [is_header_menu_compact, setIsHeaderMenuCompact] = useState(false);

	// UseEffect...
	useEffect(() => {
		// Is Header Menu Compact...
		localStorage.setItem("is_header_menu_compact", is_header_menu_compact);
		if (is_header_menu_compact) {
			document
				.querySelector("body .nk-sidebar")
				.classList.add("nk-sidebar-active");
		} else {
			document
				.querySelector("body .nk-sidebar")
				.classList.remove("nk-sidebar-active");
		}
	}, [is_header_menu_compact]);

	return (
		<>
			{/* Main */}
			<div
				className="nk-app-root"
				id="main">
				<div className="nk-main">
					{/* SideBar */}
					<div
						className="nk-sidebar nk-sidebar-fixed is-light"
						data-content="sidebarMenu">
						<SideBar
							setIsHeaderMenuCompact={setIsHeaderMenuCompact}
						/>
					</div>

					{/* Wraper */}
					<div className="nk-wrap">
						{/* Header */}
						<div className="nk-header nk-header-fixed is-light">
							<Header
								setIsHeaderMenuCompact={setIsHeaderMenuCompact}
							/>
						</div>

						{/* Main Content */}
						<div className="nk-content">
							<ProtectedRoutes />
						</div>

						{/* Footer */}
						<div className="nk-footer">
							<Footer />
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default MainLayout;
