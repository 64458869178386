import React from "react";
import { useLocation } from "../../../../Hooks";

const EditWorkType = () => {
	// Location...
	const location = useLocation();

	// Work Type ID...
	const work_type_id = location.state.work_type_id;

	console.log("work_type_id ::", work_type_id);

	return (
		<>
			<div className="nk-block">
				<div className="card shadow-sm card-stretch mt-3">
					<div className="card-inner">
						{/* Form */}
						<div className="row">
							{/* Name */}
							<div className="col-md-6 col-12">
								<div className="form-group">
									<div className="form-control-wrap">
										<label className="form-label">
											Name
										</label>
										<input
											type="text"
											className="form-control"
											name="name"
											id="name"
											placeholder="Enter Name"
											autoComplete="off"
											defaultValue="Interlock"
										/>
									</div>
								</div>
							</div>
						</div>

						<hr />
						<div className="float-right">
							{/* Submit Button */}
							<button
								type="button"
								className="btn btn-sm btn-success">
								Update
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default EditWorkType;
