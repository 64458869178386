import React from "react";
import swal from "sweetalert";
import { useNavigate } from "../../../Hooks";

const ViewUserDetail = () => {
	// Navigate...
	const navigate = useNavigate();

	// Navigate To View Company Page...
	const NavigateToViewCompanyPage = (company_id) => {
		navigate("/admin/companies/view/", {
			state: {
				company_id: company_id,
			},
		});
	};

	// Navigate To Edit Page...
	const NavigateToEditPage = (user_id) => {
		navigate("/admin/users/edit/", {
			state: {
				user_id: user_id,
			},
		});
	};

	// Handle Delete...
	const HandleDelete = (user_id) => {
		swal({
			title: `Are You Sure! To Delete The User ID # ${user_id}`,
			icon: false,
			className: "delete_modal",
			dangerMode: true,
			closeOnClickOutside: false,
			buttons: ["Cancel", "Yes I'm Sure"],
		}).then((willDelete) => {
			if (willDelete) {
				swal({
					title: `User ID # ${user_id} Deleted Successfully!`,
					icon: false,
					className: "delete_success_text",
					closeOnClickOutside: false,
					buttons: {
						confirm: { text: "Ok", className: "success_button_ok" },
					},
				});
				navigate("/admin/users");
			} else {
				swal({
					title: `User ID # ${user_id} Not Deleted!`,
					icon: false,
					className: "delete_success_text",
					closeOnClickOutside: false,
					buttons: {
						confirm: { text: "Ok", className: "success_button_ok" },
					},
				});
			}
		});
	};
	return (
		<>
			<div className="nk-block">
				<div className="card shadow-sm card-stretch mt-3">
					<div className="card-inner">
						{/* Detail */}
						<h5 className="text-secondary">User Information</h5>

						<div className="row mt-3">
							<div className="col-12 col-md-6 col-lg-4">
								<label className="form-label font-weight-bold mb-0">
									ID :
								</label>
								<p>1</p>
							</div>
							<div className="col-12 col-md-6 col-lg-4 mt-3 mt-md-0">
								<label className="form-label font-weight-bold mb-0">
									Full Name :
								</label>
								<p>nasir</p>
							</div>
							<div className="col-12 col-md-6 col-lg-4 mt-3 mt-md-0">
								<label className="form-label font-weight-bold mb-0">
									Email Address :
								</label>
								<p>nasir@mp.com</p>
							</div>
						</div>

						<div className="row mt-3">
							<div className="col-12 col-md-6 col-lg-4">
								<label className="form-label font-weight-bold mb-0">
									Password :
								</label>
								<p>123456</p>
							</div>
							<div className="col-12 col-md-6 col-lg-4 mt-3 mt-md-0">
								<label className="form-label font-weight-bold mb-0">
									Contact No :
								</label>
								<p>0123456</p>
							</div>
							<div className="col-12 col-md-6 col-lg-4 mt-3 mt-md-0">
								<label className="form-label font-weight-bold mb-0">
									Company Name :
								</label>
								<p
									className="view_link"
									onClick={() =>
										NavigateToViewCompanyPage(1)
									}>
									Sharjah General Contracting Co
								</p>
							</div>
						</div>

						<div className="row mt-3">
							<div className="col-12 col-md-6 col-lg-4">
								<label className="form-label font-weight-bold mb-0">
									Status :
								</label>
								<p>Active</p>
							</div>
							<div className="col-12 col-md-6 col-lg-4 mt-3 mt-md-0">
								<label className="form-label font-weight-bold mb-0">
									Created At :
								</label>
								<p>2021-06-24 10:44:51</p>
							</div>
							<div className="col-12 col-md-6 col-lg-4 mt-3 mt-md-0">
								<label className="form-label font-weight-bold mb-0">
									Modified At :
								</label>
								<p>2023-07-05 11:32:10</p>
							</div>
						</div>
						<hr />

						<h5 className="text-secondary">Actions</h5>
						<ul className="pl-4">
							<li
								className="view_link"
								onClick={() => NavigateToEditPage(1)}>
								Edit User
							</li>
							<li
								className="view_link"
								onClick={() => HandleDelete(1)}>
								Delete User
							</li>
							<li
								className="view_link"
								onClick={() => navigate("/admin/users")}>
								List Users
							</li>
							<li
								className="view_link"
								onClick={() => navigate("/admin/users/add")}>
								Add New User
							</li>
							<li
								className="view_link"
								onClick={() => navigate("/admin/companies")}>
								List Companies
							</li>
							<li
								className="view_link"
								onClick={() =>
									navigate("/admin/companies/add")
								}>
								Add New Company
							</li>
						</ul>
					</div>
				</div>
			</div>
		</>
	);
};

export default ViewUserDetail;
