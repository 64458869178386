import React from "react";
import { PageHeader, AddUserForm } from "../../../Components";

const AddUser = () => {
	// Site Title...
	document.title = "Add User";

	return (
		<>
			{/* Page Header */}
			<PageHeader
				heading_text="Add User"
				button="true"
				button_icon="list"
				button_text="List Users"
				button_url_link="users"
			/>

			{/* Form */}
			<AddUserForm />
		</>
	);
};

export default AddUser;
