import React from "react";
import { ListAuthorities, PageHeader } from "../../Components";

const Authorities = () => {
	// Site Title...
	document.title = "Authorities";

	return (
		<>
			{/* Page Header */}
			<PageHeader
				heading_text="Authorities"
				button="true"
				button_icon="plus"
				button_text="Add New Authority"
				button_url_link="authorities/add"
			/>

			{/* LisT Authorities */}
			<ListAuthorities />
		</>
	);
};

export default Authorities;
