import { createBrowserRouter } from "react-router-dom";
import { MainLayout } from "../Layouts";
import * as RoutePages from "../Pages";

const Routes = [
	{
		path: "/",
		element: <MainLayout />,
		errorElement: <h1>error!</h1>,
		children: [
			// Dshboard...
			{
				path: "admin/dashboard",
				element: <RoutePages.Dashboard />,
			},

			// Work Types...
			{
				path: "admin/work_types",
				element: <RoutePages.WorkTypes />,
			},
			{
				path: "admin/work_types/add",
				element: <RoutePages.AddWorkType />,
			},
			{
				path: "admin/work_types/edit",
				element: <RoutePages.EditWorkType />,
			},

			// Authorities...
			{
				path: "admin/authorities",
				element: <RoutePages.Authorities />,
			},
			{
				path: "admin/authorities/add",
				element: <RoutePages.AddAuthority />,
			},
			{
				path: "admin/authorities/edit",
				element: <RoutePages.EditAuthority />,
			},

			// Companies...
			{
				path: "admin/companies",
				element: <RoutePages.Companies />,
			},
			{
				path: "admin/companies/add",
				element: <RoutePages.AddCompany />,
			},
			{
				path: "admin/companies/view",
				element: <RoutePages.ViewCompany />,
			},
			{
				path: "admin/companies/edit",
				element: <RoutePages.EditCompany />,
			},

			// Users...
			{
				path: "admin/users",
				element: <RoutePages.Users />,
			},
			{
				path: "admin/users/add",
				element: <RoutePages.AddUser />,
			},
			{
				path: "admin/users/view",
				element: <RoutePages.ViewUser />,
			},
			{
				path: "admin/users/edit",
				element: <RoutePages.EditUser />,
			},

			// Monthly Work Orders..
			{
				path: "admin/monthly_work_orders",
				element: <RoutePages.MonthlyWorkOrders />,
			},
			{
				path: "admin/monthly_work_orders/add",
				element: <RoutePages.AddMonthlyWorkOrder />,
			},

			// Services Work Orders..
			{
				path: "admin/services_work_orders",
				element: <RoutePages.ServicesWorkOrders />,
			},
			{
				path: "admin/services_work_orders/add",
				element: <RoutePages.AddServiceWorkOrder />,
			},

			// SRTA Work Orders..
			{
				path: "admin/srta_work_orders",
				element: <RoutePages.SrtaWorkOrders />,
			},
			{
				path: "admin/srta_work_orders/add",
				element: <RoutePages.AddSrtaWorkOrders />,
			},

			// Map View...
			{
				path: "admin/map_view",
				element: <RoutePages.MapView />,
			},
		],
	},
];

const Route = createBrowserRouter(Routes);

export default Route;
