import React from "react";

const AddUser = () => {
	return (
		<>
			<div className="nk-block">
				<div className="card shadow-sm card-stretch mt-3">
					<div className="card-inner">
						{/* Form */}
						<div className="row">
							{/* Full Name */}
							<div className="col-md-6 col-12">
								<div className="form-group">
									<div className="form-control-wrap">
										<label className="form-label">
											Full Name
										</label>
										<input
											type="text"
											className="form-control"
											name="full_name"
											id="full_name"
											placeholder="Enter Full Name"
											autoComplete="off"
										/>
									</div>
								</div>
							</div>

							{/* Email Address */}
							<div className="col-md-6 col-12 mt-3 mt-md-0">
								<div className="form-group">
									<div className="form-control-wrap">
										<label className="form-label">
											Email Address
										</label>
										<input
											type="text"
											className="form-control"
											name="email_address"
											id="email_address"
											placeholder="Enter Email Address"
											autoComplete="off"
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="row mt-3">
							{/* Password */}
							<div className="col-md-6 col-12">
								<div className="form-group">
									<div className="form-control-wrap">
										<label className="form-label">
											Password
										</label>
										<input
											type="password"
											className="form-control"
											name="password"
											id="password"
											placeholder="Enter Password"
											autoComplete="off"
										/>
									</div>
								</div>
							</div>

							{/* Contact No */}
							<div className="col-md-6 col-12 mt-3 mt-md-0">
								<div className="form-group">
									<div className="form-control-wrap">
										<label className="form-label">
											Contact No
										</label>
										<input
											type="number"
											className="form-control"
											name="contact_no"
											id="contact_no"
											placeholder="Enter Contact No"
											autoComplete="off"
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="row mt-3">
							{/* Type */}
							<div className="col-md-6 col-12">
								<div className="form-group">
									<div className="form-control-wrap">
										<label className="form-label">
											Type
										</label>
										<div className="form-control-wrap ">
											<div className="form-control-select">
												<select
													className="form-control"
													name="type"
													id="type">
													<option defaultValue="Select Type">
														Select Type
													</option>
													<option defaultValue="USER">
														USER
													</option>
													<option defaultValue="ADMIN">
														ADMIN
													</option>
													<option defaultValue="SRTA">
														SRTA
													</option>
												</select>
											</div>
										</div>
									</div>
								</div>
							</div>

							{/* Company */}
							<div className="col-md-6 col-12 mt-3 mt-md-0">
								<div className="form-group">
									<div className="form-control-wrap">
										<label className="form-label">
											Company
										</label>
										<div className="form-control-wrap ">
											<div className="form-control-select">
												<select
													className="form-control"
													name="company"
													id="company">
													<option defaultValue="Select Company">
														Select Company
													</option>
													<option defaultValue="No Company">
														No Company
													</option>
													<option defaultValue="Sharjah General Contracting Co">
														Sharjah General
														Contracting Co
													</option>
												</select>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<hr />
						<div className="float-right">
							{/* Submit Button */}
							<button
								type="button"
								className="btn btn-sm btn-info">
								Save
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default AddUser;
