import React from "react";
import {
	MonthlyStatistics,
	PageHeader,
	ListMonthlyWorkOrders,
} from "../../Components";

const MonthlyWorkOrders = () => {
	// Site Title...
	document.title = "Monthly Work Orders";

	return (
		<>
			{/* Page Header */}
			<PageHeader
				heading_text="Monthly Work Orders"
				button="true"
				button_icon="plus"
				button_text="Add New Monthly Work Order"
				button_url_link="monthly_work_orders/add"
			/>

			{/* Monthly Statistics */}
			<MonthlyStatistics />

			{/* Monthly List */}
			<ListMonthlyWorkOrders />
		</>
	);
};

export default MonthlyWorkOrders;
