import React from "react";
import { useLocation, Link, useNavigate } from "../../Hooks";
import { Button } from "react-bootstrap";

const PageHeader = (props) => {
	// Location...
	const location = useLocation();

	// Current Path Name...
	const current_path_name = location.pathname;

	// Navigate...
	const naviagte = useNavigate();

	return (
		<>
			{current_path_name.split("/")[2] === "dashboard" ? (
				<div className="nk-block-head">
					<div className="nk-block-head-sub">
						<span>Welcome!</span>
					</div>
					<div className="nk-block-between-md g-4">
						<div className="nk-block-head-content">
							<h2 className="nk-block-title fw-normal">
								Administrator
							</h2>
							<div className="nk-block-des">
								<p>
									At a glance summary of your
									<Link href="/admin">Admin </Link>
									account. Have fun!
								</p>
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className="nk-block-head nk-block-head-sm card shadow-sm card-stretch px-3 py-4 mb-3">
					<div className="nk-block-between">
						<div className="nk-block-head-content">
							<h3 className="nk-block-title page-title text-primary">
								{props.heading_text}
							</h3>
						</div>

						{props.button === "true" && (
							<div className="nk-block-head-content">
								<div className="toggle-wrap nk-block-tools-toggle">
									<Button
										onClick={() =>
											naviagte(
												`/admin/${
													props.button_url_link
														? props.button_url_link
														: ""
												}`
											)
										}
										className="btn btn-dark">
										<em
											className={`icon ni ni-${props.button_icon}`}
										/>
										<span className="d-none d-md-inline-flex">
											{props.button_text}
										</span>
									</Button>
								</div>
							</div>
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default PageHeader;
