import React from "react";

const MapViewMap = () => {
	return (
		<>
			<div className="nk-block">
				<div className="card shadow-sm card-stretch mt-3">
					<div className="card-inner p-2">
						{/* Data */}
						<ul className="d-flex mb-3">
							<li>
								<sup className="text-danger mr-1">*</sup>
								Submitted
								<span className="border-right border-dark ml-1"></span>
							</li>
							<li className="ml-2 d-flex cursor_pointer">
								<em className="icon ni ni-map-pin-fill map_view_icon text-danger"></em>
								<span className="ml-1">9 - Monthly</span>
							</li>
							<li className="ml-3 d-flex cursor_pointer">
								<em className="icon ni ni-map-pin-fill map_view_icon text-success"></em>
								<span className="ml-1">6 - Services</span>
							</li>
							<li className="ml-3 d-flex cursor_pointer">
								<em className="icon ni ni-map-pin-fill map_view_icon text-info"></em>
								<span className="ml-1">3 - SRTA</span>
							</li>
						</ul>

						{/* Map */}
						<iframe
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3508.7732636520027!2d70.30300457392927!3d28.426097693428062!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39375b72b873ecfb%3A0xd2862522f2fda403!2sXpertSpot%20System!5e0!3m2!1sen!2s!4v1688810208864!5m2!1sen!2s"
							style={{
								border: 0,
								width: "100%",
								height: "500px",
								marginBottom: 0,
							}}
							title="Map View Map"
							allowFullScreen=""
							loading="lazy"
							referrerPolicy="no-referrer-when-downgrade"
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default MapViewMap;
