import React from "react";

const AddServiceWorkOrder = () => {
	return (
		<>
			<div className="nk-block">
				<div className="card shadow-sm card-stretch mt-3">
					<div className="card-inner">
						{/* Form */}
						<div className="row">
							{/* Work Order No */}
							<div className="col-md-6 col-12">
								<div className="form-group">
									<div className="form-control-wrap">
										<label className="form-label">
											Work Order No
										</label>
										<input
											type="text"
											className="form-control"
											name="work_order_no"
											id="work_order_no"
											placeholder="Enter Work Order No"
											autoComplete="off"
										/>
									</div>
								</div>
							</div>

							{/* Street/Area */}
							<div className="col-md-6 col-12 mt-3 mt-md-0">
								<div className="form-group">
									<div className="form-control-wrap">
										<label className="form-label">
											Street/Area
										</label>
										<input
											type="text"
											className="form-control"
											name="street_area"
											id="street_area"
											placeholder="Enter Street/Area"
											autoComplete="off"
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="row mt-3">
							{/* Co-Ordiantes */}
							<div className="col-md-6 col-12">
								<div className="form-group">
									<div className="form-control-wrap">
										<label className="form-label">
											Co-Ordiantes
										</label>
										<input
											type="text"
											className="form-control"
											name="co_ordinates"
											id="co_ordinates"
											placeholder="Enter Google Map Co-Ordinates"
											autoComplete="off"
										/>
									</div>
								</div>
							</div>

							{/* Map */}
							<div className="col-md-6 col-12 mt-3 mt-md-0">
								<iframe
									src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3508.7732636520027!2d70.30300457392927!3d28.426097693428062!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39375b72b873ecfb%3A0xd2862522f2fda403!2sXpertSpot%20System!5e0!3m2!1sen!2s!4v1688810208864!5m2!1sen!2s"
									style={{
										border: 0,
										width: "100%",
										height: "100px",
										marginBottom: 0,
									}}
									title="Service Work Order"
									allowFullScreen=""
									loading="lazy"
									referrerPolicy="no-referrer-when-downgrade"
								/>
							</div>
						</div>

						<div className="row mt-3">
							{/* Ready To Instate */}
							<div className="col-md-6 col-12">
								<div className="form-group">
									<div className="form-control-wrap">
										<label className="form-label">
											Ready To Instate
										</label>
										<div className="form-control-wrap ">
											<div className="form-control-select">
												<select
													className="form-control"
													name="type"
													id="type">
													<option value="Select Ready To Instate">
														Select Ready To Instate
													</option>
													<option value="Yes">
														Yes
													</option>
													<option value="No">
														No
													</option>
												</select>
											</div>
										</div>
									</div>
								</div>
							</div>

							{/* Authority */}
							<div className="col-md-6 col-12 mt-3 mt-md-0">
								<div className="form-group">
									<div className="form-control-wrap">
										<label className="form-label">
											Authority
										</label>
										<div className="form-control-wrap ">
											<div className="form-control-select">
												<select
													className="form-control"
													name="authority"
													id="authority">
													<option value="Select Authority">
														Select Authority
													</option>
													<option value="SEWA">
														SEWA
													</option>
												</select>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="row mt-3">
							{/* Attachment */}
							<div className="col-12">
								<div className="form-group">
									<div className="form-control-wrap">
										<label className="form-label">
											Attachment
										</label>
										<div className="custom-file">
											<input
												type="file"
												className="custom-file-input form-control"
												id="attachment"
												name="attachment"
											/>
											<label
												htmlFor="attachment"
												className="custom-file-label">
												Choose Attachment
											</label>
										</div>
									</div>
								</div>
							</div>
						</div>

						<hr />
						<div className="float-right">
							{/* Submit Button */}
							<button
								type="button"
								className="btn btn-sm btn-info">
								Save
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default AddServiceWorkOrder;
