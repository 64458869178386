import React from "react";
import { EditCompanyForm, PageHeader } from "../../../Components";

const EditCompany = () => {
	// Site Title...
	document.title = "Edit Company";

	return (
		<>
			{/* Page Header */}
			<PageHeader
				heading_text="Edit Company"
				button="true"
				button_icon="list"
				button_text="List Companies"
				button_url_link="companies"
			/>

			{/* Form */}
			<EditCompanyForm />
		</>
	);
};

export default EditCompany;
