import React from "react";
import { PageHeader, AddWorkTypeForm } from "../../../Components";

const AddWorkType = () => {
	// Site Title...
	document.title = "Add Work Type";

	return (
		<>
			{/* Page Header */}
			<PageHeader
				heading_text="Add Work Type"
				button="true"
				button_icon="list"
				button_text="List Work Types"
				button_url_link="work_types"
			/>

			{/* Form */}
			<AddWorkTypeForm />
		</>
	);
};

export default AddWorkType;
