import React from "react";
import swal from "sweetalert";

const ListSrtaWorkOrders = () => {
	// Handle Delete...
	const HandleDelete = (srta_work_order_id) => {
		swal({
			title: `Are You Sure! To Delete The SRTA Work Order ID # ${srta_work_order_id}`,
			icon: false,
			className: "delete_modal",
			dangerMode: true,
			closeOnClickOutside: false,
			buttons: ["Cancel", "Yes I'm Sure"],
		}).then((willDelete) => {
			if (willDelete) {
				swal({
					title: `SRTA Work Order ID # ${srta_work_order_id} Deleted Successfully!`,
					icon: false,
					className: "delete_success_text",
					closeOnClickOutside: false,
					buttons: {
						confirm: { text: "Ok", className: "success_button_ok" },
					},
				});
			} else {
				swal({
					title: `SRTA Work Order ID # ${srta_work_order_id} Not Deleted!`,
					icon: false,
					className: "delete_success_text",
					closeOnClickOutside: false,
					buttons: {
						confirm: { text: "Ok", className: "success_button_ok" },
					},
				});
			}
		});
	};

	return (
		<>
			<div className="nk-block">
				<div className="card shadow-sm card-stretch mt-3">
					<div className="card-inner p-0">
						{/* Table */}
						<table
							className="nk-tb-list nk-tb-ulist is-compact"
							data-auto-responsive="false">
							<thead>
								<tr className="nk-tb-item nk-tb-head">
									<th className="nk-tb-col">
										<span className="sub-text">SR#</span>
									</th>
									<th className="nk-tb-col">
										<span className="sub-text">
											Work Order#
										</span>
									</th>
									<th className="nk-tb-col tb-col-lg">
										<span className="sub-text">Status</span>
									</th>
									<th className="nk-tb-col tb-col-lg">
										<span className="sub-text">
											Started
										</span>
									</th>
									<th className="nk-tb-col tb-col-md">
										<span className="sub-text">NOC</span>
									</th>
									<th className="nk-tb-col tb-col-md">
										<span className="sub-text">Dated</span>
									</th>
									<th className="nk-tb-col tb-col">
										<span className="sub-text">
											Actions
										</span>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr className="nk-tb-item">
									<td className="nk-tb-col">
										<span className="sub-text">1</span>
									</td>
									<td className="nk-tb-col">
										<span className="sub-text">F54</span>
									</td>
									<td className="nk-tb-col tb-col-lg">
										<span className="sub-text">OPEN</span>
									</td>
									<td className="nk-tb-col tb-col-lg">
										<span className="sub-text">
											23-10-2021
										</span>
									</td>
									<td className="nk-tb-col tb-col-md">
										<span className="sub-text">
											PENDING
										</span>
									</td>
									<td className="nk-tb-col tb-col-md">
										<span className="sub-text">
											02-10-2021{" "}
										</span>
									</td>
									<td className="nk-tb-col tb-col">
										<div className="d-flex">
											{/* Delete */}
											<span
												onClick={() => HandleDelete(1)}
												className="text-danger ml-2 list_icon_style"
												title="Delete The SRTA Work Order!">
												<em className="icon ni ni-trash-fill"></em>
											</span>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	);
};

export default ListSrtaWorkOrders;
