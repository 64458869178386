import React from "react";
import { EditUserForm, PageHeader } from "../../../Components";

const EditUser = () => {
	// Site Title...
	document.title = "Edit User";

	return (
		<>
			{/* Page Header */}
			<PageHeader
				heading_text="Edit User"
				button="true"
				button_icon="list"
				button_text="List Users"
				button_url_link="users"
			/>

			{/* Form */}
			<EditUserForm />
		</>
	);
};

export default EditUser;
