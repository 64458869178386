import React from "react";
import swal from "sweetalert";
import { useNavigate } from "../../../Hooks";

const ListUsers = () => {
	// Navigate...
	const naviagte = useNavigate();

	// Navigate To View Page...
	const NavigateToViewPage = (user_id) => {
		naviagte("/admin/users/view/", {
			state: {
				user_id: user_id,
			},
		});
	};

	// Navigate To Edit Page...
	const NavigateToEditPage = (user_id) => {
		naviagte("/admin/users/edit/", {
			state: {
				user_id: user_id,
			},
		});
	};

	// Handle Delete...
	const HandleDelete = (user_id) => {
		swal({
			title: `Are You Sure! To Delete The User ID # ${user_id}`,
			icon: false,
			className: "delete_modal",
			dangerMode: true,
			closeOnClickOutside: false,
			buttons: ["Cancel", "Yes I'm Sure"],
		}).then((willDelete) => {
			if (willDelete) {
				swal({
					title: `User ID # ${user_id} Deleted Successfully!`,
					icon: false,
					className: "delete_success_text",
					closeOnClickOutside: false,
					buttons: {
						confirm: { text: "Ok", className: "success_button_ok" },
					},
				});
			} else {
				swal({
					title: `User ID # ${user_id} Not Deleted!`,
					icon: false,
					className: "delete_success_text",
					closeOnClickOutside: false,
					buttons: {
						confirm: { text: "Ok", className: "success_button_ok" },
					},
				});
			}
		});
	};

	return (
		<>
			<div className="nk-block">
				<div className="card shadow-sm card-stretch mt-3">
					<div className="card-inner p-0">
						{/* Table */}
						<table
							className="nk-tb-list nk-tb-ulist is-compact"
							data-auto-responsive="false">
							<thead>
								<tr className="nk-tb-item nk-tb-head">
									<th className="nk-tb-col">
										<span className="sub-text">SR#</span>
									</th>
									<th className="nk-tb-col">
										<span className="sub-text">
											Full Name
										</span>
									</th>
									<th className="nk-tb-col tb-col-lg">
										<span className="sub-text">
											Email Address
										</span>
									</th>
									<th className="nk-tb-col tb-col-lg">
										<span className="sub-text">
											Contact No
										</span>
									</th>
									<th className="nk-tb-col tb-col-md">
										<span className="sub-text">
											Comapny Name
										</span>
									</th>
									<th className="nk-tb-col tb-col-md">
										<span className="sub-text">Type</span>
									</th>
									<th className="nk-tb-col tb-col">
										<span className="sub-text">
											Actions
										</span>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr className="nk-tb-item">
									<td className="nk-tb-col">
										<span className="sub-text">1</span>
									</td>
									<td className="nk-tb-col">
										<span className="sub-text">nasir</span>
									</td>
									<td className="nk-tb-col tb-col-lg">
										<span className="sub-text">
											nasir@mp.com
										</span>
									</td>
									<td className="nk-tb-col tb-col-lg">
										<span className="sub-text">
											0123456
										</span>
									</td>
									<td className="nk-tb-col tb-col-md">
										<span className="sub-text">SGCC</span>
									</td>
									<td className="nk-tb-col tb-col-md">
										<span className="sub-text">USER</span>
									</td>
									<td className="nk-tb-col tb-col">
										<div className="d-flex">
											{/* View */}
											<span
												onClick={() =>
													NavigateToViewPage(1)
												}
												className="text-success list_icon_style"
												title="View The User!">
												<em className="icon ni ni-eye-fill"></em>
											</span>

											{/* Edit */}
											<span
												onClick={() =>
													NavigateToEditPage(1)
												}
												className="text-info ml-2 list_icon_style"
												title="Edit The User!">
												<em className="icon ni ni-edit-fill"></em>
											</span>

											{/* Delete */}
											<span
												onClick={() => HandleDelete(1)}
												className="text-danger ml-2 list_icon_style"
												title="Delete The User!">
												<em className="icon ni ni-trash-fill"></em>
											</span>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	);
};

export default ListUsers;
