import React from "react";
import { useLocation, useState, useEffect, Link } from "../../Hooks";
import { SideBarMenu } from "../../Data";

const SideBar = (props) => {
	// States...
	const [is_sidebar_menu_compact, setIsSidebarMenuCompact] = useState(false);

	// Location...
	const location = useLocation();

	// Current Path Name...
	const current_path_name = location.pathname;

	// UseEffect...
	useEffect(() => {
		// Is Sidebar Menu Compact...
		localStorage.setItem(
			"is_sidebar_menu_compact",
			is_sidebar_menu_compact
		);
		if (is_sidebar_menu_compact) {
			document
				.querySelector("body .nk-sidebar")
				.classList.add("is-compact");
		} else {
			document
				.querySelector("body .nk-sidebar")
				.classList.remove("is-compact");
		}
	}, [is_sidebar_menu_compact]);

	return (
		<>
			<div className="nk-sidebar-element nk-sidebar-head">
				{/* Logo */}
				<div className="nk-sidebar-brand">
					<Link
						href="/admin/dashboard"
						className="logo-link nk-sidebar-logo">
						<img
							className="logo-small logo-img logo-img-small float-left"
							src="/backend/images/logo-small.png"
							alt="logo-small"
						/>

						<h4 className="text-primary text-capitalize logo-img dashboard_logo">
							Maintenance Portal
						</h4>
					</Link>
				</div>

				{/* Menu Icon */}
				<div className="nk-menu-trigger mr-n2">
					<Link
						to={`${current_path_name}`}
						className="nk-nav-toggle nk-quick-nav-icon d-xl-none"
						data-target="sidebarMenu"
						onClick={() => props.setIsHeaderMenuCompact(false)}>
						<em className="icon ni ni-arrow-left"></em>
					</Link>
					<Link
						to={`${current_path_name}`}
						className="nk-nav-compact nk-quick-nav-icon d-none d-xl-inline-flex"
						data-target="sidebarMenu"
						onClick={() =>
							setIsSidebarMenuCompact(!is_sidebar_menu_compact)
						}>
						<em className="icon ni ni-menu"></em>
					</Link>
				</div>
			</div>

			{/* Menu Links */}
			<div className="nk-sidebar-element">
				<div className="nk-sidebar-content">
					<div
						className="nk-sidebar-menu"
						data-simplebar>
						<ul className="nk-menu">
							{SideBarMenu &&
								SideBarMenu.length >= 1 &&
								SideBarMenu.map((item, index) => {
									return (
										<li
											key={index}
											className={`nk-menu-item ${
												current_path_name.split(
													"/"
												)[2] === item.url_link
													? "active current-page"
													: ""
											}`}>
											<Link
												to={`/admin/${item.url_link}`}
												className="nk-menu-link sidebar_link">
												<span className="nk-menu-icon">
													<em
														className={`icon ni ni-${item.icon}`}
													/>
												</span>
												<span className="nk-menu-text">
													{item.title}
												</span>
											</Link>
										</li>
									);
								})}
						</ul>
					</div>
				</div>
			</div>
		</>
	);
};

export default SideBar;
