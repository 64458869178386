import React from "react";

const AddMonthlyWorkOrder = () => {
	return (
		<>
		<div className="nk-block">
		  <div className="card shadow-sm card-stretch mt-3">
			<div className="card-inner">
			  {/* Form */}
			  <div className="row">
				{/* Title */}
				<div className="col-md-6 col-12">
				  <div className="form-group">
					<div className="form-control-wrap">
					  <label className="form-label">Title</label>
					  <input
						type="text"
						className="form-control"
						name="title"
						id="title"
						placeholder="Enter Title"
						autoComplete="off"
					  />
					</div>
				  </div>
				</div>
  
				{/* Complain No */}
				<div className="col-md-6 col-12 mt-3 mt-md-0">
				  <div className="form-group">
					<div className="form-control-wrap">
					  <label className="form-label">Complain No</label>
					  <input
						type="text"
						className="form-control"
						name="email_address"
						id="email_address"
						placeholder="Enter Complain No"
						autoComplete="off"
					  />
					</div>
				  </div>
				</div>
			  </div>
  
			  <div className="row mt-3">
				{/* Street */}
				<div className="col-md-6 col-12">
				  <div className="form-group">
					<div className="form-control-wrap">
					  <label className="form-label">Street</label>
					  <div className="form-control-wrap ">
						<div className="form-control-select">
						  <select className="form-control" name="type" id="type">
							<option value="Select Street">Select Street</option>
							<option value="Main Road">Main Road</option>
						  </select>
						</div>
					  </div>
					</div>
				  </div>
				</div>
  
				{/* Work Type */}
				<div className="col-md-6 col-12 mt-3 mt-md-0">
				  <div className="form-group">
					<div className="form-control-wrap">
					  <label className="form-label">Work Type</label>
					  <div className="form-control-wrap ">
						<div className="form-control-select">
						  <select
							className="form-control"
							name="company"
							id="company"
						  >
							<option value="Select Work Type">
							  Select Work Type
							</option>
							<option value="Interlock">Interlock</option>
						  </select>
						</div>
					  </div>
					</div>
				  </div>
				</div>
			  </div>
  
			  <div className="row mt-3">
				{/* Co-Ordiantes */}
				<div className="col-md-6 col-12">
				  <div className="form-group">
					<div className="form-control-wrap">
					  <label className="form-label">Co-Ordiantes</label>
					  <input
						type="text"
						className="form-control"
						name="co_ordinates"
						id="co_ordinates"
						placeholder="Enter Google Map Co-Ordinates"
						autoComplete="off"
					  />
					</div>
				  </div>
				</div>
  
				{/* Map */}
				<div className="col-md-6 col-12 mt-3 mt-md-0">
				  <iframe
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3508.7732636520027!2d70.30300457392927!3d28.426097693428062!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39375b72b873ecfb%3A0xd2862522f2fda403!2sXpertSpot%20System!5e0!3m2!1sen!2s!4v1688810208864!5m2!1sen!2s"
					style={{
					  border: 0,
					  width: "100%",
					  height: "100px",
					  marginBottom: 0,
					}}
					title="Monthly Work Order"
					allowFullScreen=""
					loading="lazy"
					referrerPolicy="no-referrer-when-downgrade"
				  />
				</div>
			  </div>
  
			  <div className="row mt-3">
				{/* Duration Type */}
				<div className="col-md-6 col-12">
				  <div className="form-group">
					<div className="form-control-wrap">
					  <label className="form-label">Duration Type</label>
					  <div className="form-control-wrap ">
						<div className="form-control-select">
						  <select
							className="form-control"
							name="duration_type"
							id="duration_type"
						  >
							<option value="Select Duration Type">
							  Select Duration Type
							</option>
							<option value="Urgent">Urgent</option>
							<option value="Hours">Hours</option>
							<option value="Days">Days</option>
						  </select>
						</div>
					  </div>
					</div>
				  </div>
				</div>
  
				{/* Duration */}
				<div className="col-md-6 col-12 mt-3 mt-md-0">
				  <div className="form-group">
					<div className="form-control-wrap">
					  <label className="form-label">Duration</label>
					  <input
						type="text"
						className="form-control disabled_field"
						name="duration"
						id="duration"
						placeholder="Enter Duration"
						autoComplete="off"
						defaultValue="00"
					  />
					</div>
				  </div>
				</div>
			  </div>
  
			  <div className="row mt-3">
				{/* Fix Type */}
				<div className="col-md-6 col-12">
				  <div className="form-group">
					<div className="form-control-wrap">
					  <label className="form-label">Fix Type</label>
					  <div className="form-control-wrap ">
						<div className="form-control-select">
						  <select
							className="form-control"
							name="fix_type"
							id="fix_type"
						  >
							<option value="Select Fix Type">
							  Select Fix Type
							</option>
							<option value="Temporary">Temporary</option>
							<option value="Proper">Proper</option>
						  </select>
						</div>
					  </div>
					</div>
				  </div>
				</div>
  
				{/* Attachment */}
				<div className="col-md-6 col-12 mt-3 mt-md-0">
				  <div className="form-group">
					<div className="form-control-wrap">
					  <label className="form-label">Attachment</label>
					  <div className="custom-file">
						<input
						  type="file"
						  className="custom-file-input form-control"
						  id="attachment"
						  name="attachment"
						/>
						<label for="attachment" className="custom-file-label">
						  Choose Attachment
						</label>
					  </div>
					</div>
				  </div>
				</div>
			  </div>
  
			  <hr />
			  <div className="float-right">
				{/* Submit Button */}
				<button
				  type="button"
				  className="btn btn-sm btn-info"
				>
				  Save
				</button>
			  </div>
			</div>
		  </div>
		</div>
	  </>
	);
};

export default AddMonthlyWorkOrder;
