import React from "react";
import { EditAuthorityForm, PageHeader } from "../../../Components";

const EditAuthority = () => {
	// Site Title...
	document.title = "Edit Authority";

	return (
		<>
			{/* Page Header */}
			<PageHeader
				heading_text="Edit Authority"
				button="true"
				button_icon="list"
				button_text="List Authorities"
				button_url_link="authorities"
			/>

			{/* Form */}
			<EditAuthorityForm />
		</>
	);
};

export default EditAuthority;
