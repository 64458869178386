import React from "react";
import { useLocation } from "../../../../Hooks";

const EditAuthority = () => {
	// Location...
	const location = useLocation();

	// Authority ID...
	const authority_id = location.state.authority_id;

	console.log("authority_id ::", authority_id);

	return (
		<>
			<div className="nk-block">
				<div className="card shadow-sm card-stretch mt-3">
					<div className="card-inner">
						{/* Form */}
						<div className="row">
							{/* Name */}
							<div className="col-md-6 col-12">
								<div className="form-group">
									<div className="form-control-wrap">
										<label className="form-label">
											Name
										</label>
										<input
											type="text"
											className="form-control"
											name="name"
											id="name"
											placeholder="Enter Name"
											autoComplete="off"
											defaultValue="SEWA"
										/>
									</div>
								</div>
							</div>

							{/* Is Active */}
							<div className="col-md-6 col-12 mt-3 mt-md-0">
								<div className="form-group">
									<div className="form-control-wrap">
										<label className="form-label">
											Is Active
										</label>
										<input
											type="number"
											className="form-control"
											name="is_active"
											id="is_active"
											placeholder="Enter Is Active"
											autoComplete="off"
											defaultValue="1"
										/>
									</div>
								</div>
							</div>
						</div>

						<hr />
						<div className="float-right">
							{/* Submit Button */}
							<button
								type="button"
								className="btn btn-sm btn-success">
								Update
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default EditAuthority;
