import React from "react";
import {
	ServicesStatistics,
	PageHeader,
	ListServicesWorkOrders,
} from "../../Components";

const ServicesWorkOrders = () => {
	// Site Title...
	document.title = "Services Work Orders";

	return (
		<>
			{/* Page Header */}
			<PageHeader
				heading_text="Services Work Orders"
				button="true"
				button_icon="plus"
				button_text="Add New Service Work Order"
				button_url_link="services_work_orders/add"
			/>

			{/* Services Statistics */}
			<ServicesStatistics />

			{/* Services List */}
			<ListServicesWorkOrders />
		</>
	);
};

export default ServicesWorkOrders;
