import React from "react";

const SrtaStatistics = () => {
	return (
		<>
			<div className="nk-block">
				<div className="card shadow-sm card-stretch mt-3">
					<div className="card-inner p-2">
						{/* Tabs */}
						<div>
							<ul className="nav nav-tabs">
								<li className="nav-item">
									<a
										className="nav-link active"
										data-toggle="tab"
										href="#statistics">
										Statistics
									</a>
								</li>
							</ul>
							<div className="tab-content">
								<div
									className="tab-pane active"
									id="statistics">
									<div className="row g-gs">
										{/* Total */}
										<div className="col-sm-12 col-md-4 col-lg-3">
											<div className="card shadow-sm">
												<div className="nk-ecwg nk-ecwg6">
													<div className="card-inner">
														{/* <em className="icon ni ni-alert-circle-fill text-info statistics_card_icon"></em> */}
														<div className="data mt-0">
															<div className="data-group">
																<div className="amount statistics_amount_size">
																	08
																</div>
															</div>
															<div className="info">
																<span>
																	Total
																</span>
															</div>
															<div className="progress mt-1">
																<div
																	className="progress-bar bg-info rounded"
																	data-progress="10"
																	style={{
																		height: "6px",
																		width: "100%",
																	}}></div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>

										{/* Open */}
										<div className="col-sm-12 col-md-4 col-lg-3">
											<div className="card shadow-sm">
												<div className="nk-ecwg nk-ecwg6">
													<div className="card-inner">
														{/* <em className="icon ni ni-alert-circle-fill text-primary statistics_card_icon"></em> */}
														<div className="data mt-0">
															<div className="data-group">
																<div className="amount statistics_amount_size">
																	05
																</div>
															</div>
															<div className="info">
																<span>
																	Open
																</span>
															</div>
															<div className="progress mt-1">
																<div
																	className="progress-bar bg-primary rounded"
																	data-progress="10"
																	style={{
																		height: "6px",
																		width: "62.5%",
																	}}></div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>

										{/* NOC Pending */}
										<div className="col-sm-12 col-md-4 col-lg-3">
											<div className="card shadow-sm">
												<div className="nk-ecwg nk-ecwg6">
													<div className="card-inner">
														{/* <em className="icon ni ni-alert-circle-fill text-danger statistics_card_icon"></em> */}
														<div className="data mt-0">
															<div className="data-group">
																<div className="amount statistics_amount_size">
																	01
																</div>
															</div>
															<div className="info">
																<span>
																	NOC Pending
																</span>
															</div>
															<div className="progress mt-1">
																<div
																	className="progress-bar bg-danger rounded"
																	data-progress="10"
																	style={{
																		height: "6px",
																		width: "12.5%",
																	}}></div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>

										{/* On-Going */}
										<div className="col-sm-12 col-md-4 col-lg-3">
											<div className="card shadow-sm">
												<div className="nk-ecwg nk-ecwg6">
													<div className="card-inner">
														{/* <em className="icon ni ni-alert-circle-fill text-dark statistics_card_icon"></em> */}
														<div className="data mt-0">
															<div className="data-group">
																<div className="amount statistics_amount_size">
																	04
																</div>
															</div>
															<div className="info">
																<span>
																	On-Going
																</span>
															</div>
															<div className="progress mt-1">
																<div
																	className="progress-bar bg-dark rounded"
																	data-progress="10"
																	style={{
																		height: "6px",
																		width: "0%",
																	}}></div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>

										{/* Late */}
										<div className="col-sm-12 col-md-4 col-lg-3">
											<div className="card shadow-sm">
												<div className="nk-ecwg nk-ecwg6">
													<div className="card-inner">
														{/* <em className="icon ni ni-alert-circle-fill text-dark statistics_card_icon"></em> */}
														<div className="data mt-0">
															<div className="data-group">
																<div className="amount statistics_amount_size">
																	00
																</div>
															</div>
															<div className="info">
																<span>
																	Late
																</span>
															</div>
															<div className="progress mt-1">
																<div
																	className="progress-bar bg-dark rounded"
																	data-progress="10"
																	style={{
																		height: "6px",
																		width: "0%",
																	}}></div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>

										{/* Submitted */}
										<div className="col-sm-12 col-md-4 col-lg-3">
											<div className="card shadow-sm">
												<div className="nk-ecwg nk-ecwg6">
													<div className="card-inner">
														{/* <em className="icon ni ni-alert-circle-fill text-warning statistics_card_icon"></em> */}
														<div className="data mt-0">
															<div className="data-group">
																<div className="amount statistics_amount_size">
																	01
																</div>
															</div>
															<div className="info">
																<span>
																	Submitted
																</span>
															</div>
															<div className="progress mt-1">
																<div
																	className="progress-bar bg-warning rounded"
																	data-progress="10"
																	style={{
																		height: "6px",
																		width: "12.5%",
																	}}></div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>

										{/* Completed */}
										<div className="col-sm-12 col-md-4 col-lg-3">
											<div className="card shadow-sm">
												<div className="nk-ecwg nk-ecwg6">
													<div className="card-inner">
														{/* <em className="icon ni ni-alert-circle-fill text-success statistics_card_icon"></em> */}
														<div className="data mt-0">
															<div className="data-group">
																<div className="amount statistics_amount_size">
																	01
																</div>
															</div>
															<div className="info">
																<span>
																	Completed
																</span>
															</div>
															<div className="progress mt-1">
																<div
																	className="progress-bar bg-success rounded"
																	data-progress="10"
																	style={{
																		height: "6px",
																		width: "12.5%",
																	}}></div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>

										{/* Canceleld */}
										<div className="col-sm-12 col-md-4 col-lg-3">
											<div className="card shadow-sm">
												<div className="nk-ecwg nk-ecwg6">
													<div className="card-inner">
														{/* <em className="icon ni ni-alert-circle-fill text-danger statistics_card_icon"></em> */}
														<div className="data mt-0">
															<div className="data-group">
																<div className="amount statistics_amount_size">
																	01
																</div>
															</div>
															<div className="info">
																<span>
																	Canceleld
																</span>
															</div>
															<div className="progress mt-1">
																<div
																	className="progress-bar bg-danger rounded"
																	data-progress="10"
																	style={{
																		height: "6px",
																		width: "12.5%",
																	}}></div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default SrtaStatistics;
