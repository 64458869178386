import React from "react";
import { PageHeader, AddSrtaWorkOrderForm } from "../../../Components";

const AddSrtaWorkOrders = () => {
	// Site Title...
	document.title = "Add SRTA Work Order";

	return (
		<>
			{/* Page Header */}
			<PageHeader
				heading_text="Add SRTA Work Order"
				button="true"
				button_icon="list"
				button_text="List SRTA Work Orders"
				button_url_link="srta_work_orders"
			/>

			{/* Form */}
			<AddSrtaWorkOrderForm />
		</>
	);
};

export default AddSrtaWorkOrders;
