import React from "react";
import { CompanyDetail, PageHeader } from "../../../Components";

const ViewCompany = () => {
	// Site Title...
	document.title = "View Company";

	return (
		<>
			{/* Page Header */}
			<PageHeader
				heading_text="View Company"
				button="true"
				button_icon="list"
				button_text="List Companies"
				button_url_link="companies"
			/>

			{/* CompanyDetail */}
			<CompanyDetail />
		</>
	);
};

export default ViewCompany;
