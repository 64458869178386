import React from "react";
import { EditWorkTypeForm, PageHeader } from "../../../Components";

const EditWorkType = () => {
	// Site Title...
	document.title = "Edit Work Type";

	return (
		<>
			{/* Page Header */}
			<PageHeader
				heading_text="Edit Work Type"
				button="true"
				button_icon="list"
				button_text="List Work Types"
				button_url_link="work_types"
			/>

			{/* Form */}
			<EditWorkTypeForm />
		</>
	);
};

export default EditWorkType;
