import React from "react";
import { Map, PageHeader } from "../../Components";

const MapView = () => {
	// Site Title...
	document.title = "Map View";

	return (
		<>
			{/* Page Header */}
			<PageHeader
				heading_text="Map View"
				button="false"
			/>

			{/* LisT WorK Types */}
			<Map />
		</>
	);
};

export default MapView;
