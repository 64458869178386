import React from "react";
import swal from "sweetalert";
import { useNavigate } from "../../../Hooks";

const ViewCompanyDetail = () => {
	// Navigate...
	const navigate = useNavigate();

	// Navigate To Edit Page...
	const NavigateToEditPage = (company_id) => {
		navigate("/admin/companies/edit/", {
			state: {
				company_id: company_id,
			},
		});
	};

	// Handle Delete...
	const HandleDelete = (company_id) => {
		swal({
			title: `Are You Sure! To Delete The Company ID # ${company_id}`,
			icon: false,
			className: "delete_modal",
			dangerMode: true,
			closeOnClickOutside: false,
			buttons: ["Cancel", "Yes I'm Sure"],
		}).then((willDelete) => {
			if (willDelete) {
				swal({
					title: `Company ID # ${company_id} Deleted Successfully!`,
					icon: false,
					className: "delete_success_text",
					closeOnClickOutside: false,
					buttons: {
						confirm: { text: "Ok", className: "success_button_ok" },
					},
				});
				navigate("/admin/companies")
			} else {
				swal({
					title: `Company ID # ${company_id} Not Deleted!`,
					icon: false,
					className: "delete_success_text",
					closeOnClickOutside: false,
					buttons: {
						confirm: { text: "Ok", className: "success_button_ok" },
					},
				});
			}
		});
	};
	return (
		<>
			<div className="nk-block">
				<div className="card shadow-sm card-stretch mt-3">
					<div className="card-inner">
						{/* Detail */}
						<h5 className="text-secondary">Company Information</h5>

						<div className="row mt-3">
							<div className="col-12 col-md-6 col-lg-4">
								<label className="form-label font-weight-bold mb-0">
									ID :
								</label>
								<p>1</p>
							</div>
							<div className="col-12 col-md-6 col-lg-4 mt-3 mt-md-0">
								<label className="form-label font-weight-bold mb-0">
									Name :
								</label>
								<p>Sharjah General Contracting Co</p>
							</div>
							<div className="col-12 col-md-6 col-lg-4 mt-3 mt-md-0">
								<label className="form-label font-weight-bold mb-0">
									Short Name :
								</label>
								<p>SGCC</p>
							</div>
						</div>
						<hr />

						<h5 className="text-secondary">Actions</h5>
						<ul className="pl-4">
							<li
								className="view_link"
								onClick={() => NavigateToEditPage(1)}>
								Edit Company
							</li>
							<li
								className="view_link"
								onClick={() => HandleDelete(1)}>
								Delete Company
							</li>
							<li
								className="view_link"
								onClick={() => navigate("/admin/companies")}>
								List Companies
							</li>
							<li
								className="view_link"
								onClick={() =>
									navigate("/admin/companies/add")
								}>
								Add New Company
							</li>
						</ul>
					</div>
				</div>
			</div>
		</>
	);
};

export default ViewCompanyDetail;
