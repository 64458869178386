import React from "react";
import { PageHeader, AddAuthorityForm } from "../../../Components";

const AddAuthority = () => {
	// Site Title...
	document.title = "Add Authority";

	return (
		<>
			{/* Page Header */}
			<PageHeader
				heading_text="Add Authority"
				button="true"
				button_icon="list"
				button_text="List Authorities"
				button_url_link="authorities"
			/>

			{/* Form */}
			<AddAuthorityForm />
		</>
	);
};

export default AddAuthority;
