import React from "react";
import { PageHeader, AddServiceWorkOrderForm } from "../../../Components";

const AddServiceWorkOrders = () => {
	// Site Title...
	document.title = "Add Service Work Order";

	return (
		<>
			{/* Page Header */}
			<PageHeader
				heading_text="Add Service Work Order"
				button="true"
				button_icon="list"
				button_text="List Services Work Orders"
				button_url_link="services_work_orders"
			/>

			{/* Form */}
			<AddServiceWorkOrderForm />
		</>
	);
};

export default AddServiceWorkOrders;
