import React from "react";
import {
	SrtaStatistics,
	PageHeader,
	ListSrtaWorkOrders,
} from "../../Components";

const SrtaWorkOrders = () => {
	// Site Title...
	document.title = "SRTA Work Orders";

	return (
		<>
			{/* Page Header */}
			<PageHeader
				heading_text="SRTA Work Orders"
				button="true"
				button_icon="plus"
				button_text="Add New SRTA Work Order"
				button_url_link="srta_work_orders/add"
			/>

			{/* SRTA Statistics */}
			<SrtaStatistics />

			{/* SRTA Statistics */}
			<ListSrtaWorkOrders />
		</>
	);
};

export default SrtaWorkOrders;
