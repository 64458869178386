import React from "react";
import { Navigate, Outlet, useLocation } from "../../Hooks";

const ProtectedRoutes = () => {
	// Location...
	const location = useLocation();

	// Current Path Name...
	const current_path_name = location.pathname;

	return (
		<>
			{current_path_name === "/" ? (
				<Navigate to="/admin/dashboard" />
			) : (
				<Outlet />
			)}
		</>
	);
};

export default ProtectedRoutes;
