import React from "react";
import { ListCompanies, PageHeader } from "../../Components";

const Companies = () => {
	// Site Title...
	document.title = "Companies";

	return (
		<>
			{/* Page Header */}
			<PageHeader
				heading_text="Companies"
				button="true"
				button_icon="plus"
				button_text="Add New Company"
				button_url_link="companies/add"
			/>

			{/* LisT Companies */}
			<ListCompanies />
		</>
	);
};

export default Companies;
