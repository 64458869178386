import React from "react";
import { PageHeader } from "../../Components";

const Dashboard = () => {
	// Site Title...
	document.title = "Dashboard";

	return (
		<>
			{/* Page Header */}
			<PageHeader />
		</>
	);
};

export default Dashboard;
