import React from "react";
import { Button } from "react-bootstrap";
import { useLocation, useState, useEffect, Link } from "../../Hooks";

const Header = (props) => {
	// States...
	const [is_header_dropdown_open, setIsHeaderDropdownOpen] = useState(false);

	// Location...
	const location = useLocation();

	// Current Path Name...
	const current_path_name = location.pathname;

	// UseEffect...
	useEffect(() => {
		// Is Header Dropdwon Open...
		localStorage.setItem(
			"is_header_dropdown_open",
			is_header_dropdown_open
		);
		if (is_header_dropdown_open) {
			document.querySelector(".dropdown-toggle").classList.add("show");
			document
				.querySelector(".dropdown-menu-right")
				.classList.add("show");
		} else {
			document.querySelector(".dropdown-toggle").classList.remove("show");
			document
				.querySelector(".dropdown-menu-right")
				.classList.remove("show");
		}
	}, [is_header_dropdown_open]);

	return (
		<>
			<div className="container-fluid">
				<div className="nk-header-wrap">
					<div className="nk-menu-trigger d-xl-none ml-n1">
						<Link
							to={`${current_path_name}`}
							className="nk-nav-toggle nk-quick-nav-icon"
							data-target="sidebarMenu"
							onClick={() => props.setIsHeaderMenuCompact(true)}>
							<em className="icon ni ni-menu"></em>
						</Link>
					</div>
					<div className="nk-header-brand d-xl-none">
						<Link
							href="/admin/dashboard"
							className="logo-link nk-sidebar-logo">
							<img
								className="logo-small logo-img logo-img-small float-left"
								src="/backend/images/logo-small.png"
								alt="logo-small"
							/>

							<h4 className="text-primary text-capitalize dashboard_logo">
								Maintenance Portal
							</h4>
						</Link>
					</div>

					<div className="nk-header-tools">
						<ul className="nk-quick-nav">
							<li className="dropdown user-dropdown">
								<div
									className="dropdown-toggle mr-n1 cursor_pointer"
									data-toggle="dropdown"
									onClick={() =>
										setIsHeaderDropdownOpen(
											!is_header_dropdown_open
										)
									}>
									<div className="user-toggle">
										<div className="user-avatar sm">
											<img src="/backend/images/avatar/b-sm.jpg" alt="avatar" />
										</div>
										<div className="user-info d-none d-xl-block">
											<div className="user-status user-status-unverified">
												admin@admin.com
											</div>
											<div className="user-name dropdown-indicator">
												Administrator
											</div>
										</div>
									</div>
								</div>
								<div className="dropdown-menu dropdown-menu-md dropdown-menu-right">
									<div className="dropdown-inner user-card-wrap bg-lighter">
										<div className="user-card">
											<div className="user-avatar">
												<img src="/backend/images/avatar/b-sm.jpg" alt="avatar" />
											</div>
											<div className="user-info">
												<span className="lead-text">
													Administrator
												</span>
												<span className="sub-text">
													admin@admin.com
												</span>
											</div>
										</div>
									</div>

									<div className="dropdown-inner">
										<ul className="link-list">
											<li>
												<Button className="btn btn-danger btn-block">
													<em className="icon ni ni-signout" />
													<span>Log Out</span>
												</Button>
											</li>
										</ul>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</>
	);
};

export default Header;
