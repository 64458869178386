import React from "react";
import { UserDetail, PageHeader } from "../../../Components";

const ViewUser = () => {
	// Site Title...
	document.title = "View User";

	return (
		<>
			{/* Page Header */}
			<PageHeader
				heading_text="View User"
				button="true"
				button_icon="list"
				button_text="List Users"
				button_url_link="users"
			/>

			{/* UserDetail */}
			<UserDetail />
		</>
	);
};

export default ViewUser;
